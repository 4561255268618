import { Props } from '.'
import { AccessibleButton } from '../styled'
import styled, { css } from 'styled-components'
import { colors, fontFamily, media, transition } from '@variables'

export const Wrap = styled(AccessibleButton)<Pick<Props, 'color' | 'outlined'>>`
    border-radius: 6px;
    padding: 18px 40px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.04em;
    border: 1px solid transparent;
    transition: ${transition.main};
    font-family: ${fontFamily.bold};
    box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.85);

    ${({ color, outlined }) => {
        if (color === 'white' && !outlined) {
            return css`
                color: ${colors.black};
                background-color: ${colors.white};

                ${media.tablet} {
                    &:hover {
                        box-shadow: 0px 0px 20px ${colors.white};
                    }
                }
            `
        }
        if (color === 'white' && outlined) {
            return css`
                box-shadow: none;
                color: ${colors.white};
                background-color: ${colors.black};
                border: 1px solid ${colors.white};

                ${media.tablet} {
                    &:hover {
                        color: ${colors.black};
                        background-color: ${colors.white};
                    }
                }
            `
        }
        if (color === 'yellow' && !outlined) {
            return css`
                color: ${colors.black};
                background-color: ${colors.yellow};

                ${media.tablet} {
                    &:hover {
                        box-shadow: 0px 0px 20px ${colors.yellow};
                    }
                }
            `
        }
        if (color === 'yellow' && outlined) {
            return css`
                box-shadow: none;
                color: ${colors.yellow};
                background-color: ${colors.black};

                ${media.tablet} {
                    &:hover {
                        color: ${colors.black};
                        background-color: ${colors.yellow};
                    }
                }
            `
        }
        if (color === 'black' && !outlined) {
            return css`
                color: ${colors.white};
                background-color: ${colors.black};

                ${media.tablet} {
                    &:hover {
                        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.35);
                    }
                }
            `
        }
        return css`
            box-shadow: none;
            color: ${colors.black};
            background-color: ${colors.white};
            border: 1px solid ${colors.black};

            ${media.tablet} {
                &:hover {
                    color: ${colors.white};
                    background-color: ${colors.black};
                }
            }
        `
    }}
`
