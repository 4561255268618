import React, { CSSProperties } from 'react'
import DiscordIcon from 'client/components/SVG/Discord'
import TwitterIcon from 'client/components/SVG/Twitter'
import InstagramIcon from 'client/components/SVG/Instagram'
import OpenseaIcon from 'client/components/SVG/Opensea'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors, media, transition } from '@variables'

type Props = {
    color: 'white' | 'black'
    style?: CSSProperties
}

export const Wrap = styled.div<Pick<Props, 'color'>>`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        transform: scale(1);
        will-change: transform;
        transition: ${transition.main};

        path {
            ${({ color }) => {
                if (color === 'black') {
                    return css`
                        fill: ${colors.black};
                    `
                }
                return css`
                    fill: ${colors.white};
                `
            }}
        }
    }

    a {
        width: 24px;
        height: 24px;

        ${media.tablet} {
            :hover {
                svg {
                    transform: scale(1.2);
                }
            }
        }
    }

    a:not(:last-of-type) {
        margin-right: 14px;
    }
`

const Social = ({ color, style = {} }: Props) => {
    const [t] = useTranslation()

    return (
        <Wrap color={color} style={style}>
            <a
                target="_blank"
                href={t('opensea-link')}
                rel="noopener noreferrer"
            >
                <OpenseaIcon />
            </a>
            <a
                target="_blank"
                href={t('discord-link')}
                rel="noopener noreferrer"
            >
                <DiscordIcon />
            </a>
            <a
                target="_blank"
                href={t('twitter-link')}
                rel="noopener noreferrer"
            >
                <TwitterIcon />
            </a>
            <a
                target="_blank"
                href={t('instagram-link')}
                rel="noopener noreferrer"
            >
                <InstagramIcon />
            </a>
        </Wrap>
    )
}

export default Social
