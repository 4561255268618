import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="#fff"
                d="M19.952 5.672c-1.904-1.531-4.916-1.79-5.044-1.801a.477.477 0 00-.474.281 3.715 3.715 0 00-.145.398c1.259.212 2.806.64 4.206 1.509a.48.48 0 01-.505.813C15.584 5.38 12.578 5.305 12 5.305c-.578 0-3.585.075-5.989 1.567a.479.479 0 11-.505-.813c1.4-.868 2.946-1.297 4.206-1.509-.074-.236-.14-.386-.145-.398a.473.473 0 00-.475-.28c-.127.01-3.139.269-5.069 1.822C3.015 6.625 1 12.073 1 16.783a.48.48 0 00.063.237c1.391 2.443 5.185 3.083 6.05 3.111h.015a.478.478 0 00.387-.197l.875-1.202c-2.359-.61-3.564-1.645-3.634-1.706a.478.478 0 01.632-.718c.029.026 2.248 1.909 6.612 1.909 4.372 0 6.591-1.891 6.613-1.91a.479.479 0 01.632.718c-.07.062-1.275 1.096-3.634 1.706l.875 1.202c.09.124.234.197.387.197h.015c.865-.027 4.659-.667 6.05-3.111a.486.486 0 00.062-.236c0-4.71-2.015-10.158-3.048-11.111zM8.891 14.87c-.924 0-1.674-.857-1.674-1.913s.749-1.913 1.674-1.913c.925 0 1.674.857 1.674 1.913s-.749 1.913-1.674 1.913zm6.218 0c-.924 0-1.674-.857-1.674-1.913s.749-1.913 1.674-1.913c.924 0 1.674.857 1.674 1.913s-.75 1.913-1.674 1.913z"
            ></path>
        </svg>
    )
}

export default Icon
