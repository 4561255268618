import { colors, Routes } from '@variables'
import { Link } from 'react-router-dom'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Web3Context } from 'client/context/Web3'
import Button from 'client/components/shared/Button'
import Social from 'client/components/shared/Social'
import { Wrap, ButtonWrap, SocialWrap, Menu } from './styled'

export type Props = {
    open: boolean
    close: () => void
}

const MobileMenu = ({ open, close }: Props) => {
    const [t] = useTranslation()
    const { hahdleMintButton, selectedAccount } = useContext(Web3Context)

    return (
        <Wrap open={open}>
            <Menu>
                <Link
                    to={`${Routes.HOME}#${Routes.ABOUT_HASH}`}
                    onClick={close}
                >
                    {t('nav-about')}
                </Link>
                <Link
                    to={`${Routes.HOME}#${Routes.COLLECTION_HASH}`}
                    onClick={close}
                >
                    {t('nav-collection')}
                </Link>
                <Link to={`${Routes.HOME}#${Routes.APP_HASH}`} onClick={close}>
                    {t('nav-app')}
                </Link>
                <Link
                    to={`${Routes.HOME}#${Routes.ROADMAP_HASH}`}
                    onClick={close}
                >
                    {t('nav-roadmap')}
                </Link>
                {Boolean(selectedAccount) && (
                    <Link
                        onClick={close}
                        to={Routes.COLLECTION}
                        style={{ color: colors.yellow }}
                    >
                        {t('my-collection')}
                    </Link>
                )}
            </Menu>

            <ButtonWrap>
                <Button
                    color="white"
                    onClick={async () => {
                        close()
                        await hahdleMintButton()
                    }}
                >
                    <span>{t('mint-now')}</span>
                </Button>
            </ButtonWrap>

            <SocialWrap>
                <Social color="white" />
            </SocialWrap>
        </Wrap>
    )
}

export default MobileMenu
