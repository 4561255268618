import React from 'react'
import styled from 'styled-components'
import LlamaVideo from '@static/images/llama.mp4'

const Wrap = styled.div`
    width: 287px;
    height: 327px;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const Video = () => (
    <Wrap>
        <video loop={true} muted={true} autoPlay={true} controls={false}>
            <source src={LlamaVideo} />
        </video>
    </Wrap>
)

export default Video
