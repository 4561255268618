import last from 'lodash/last'
import MobileMenu from './Mobile'
import { Routes } from '@variables'
import { useMedia } from 'react-use'
import Button from '../shared/Button'
import isString from 'lodash/isString'
import Container from '../shared/Container'
import { useTranslation } from 'react-i18next'
import LogoImg from '@static/images/logo.webp'
import Close from 'client/components/SVG/Close'
import Burger from 'client/components/SVG/Burger'
import { Web3Context } from 'client/context/Web3'
import Social from 'client/components/shared/Social'
import { Link, useLocation } from 'react-router-dom'
import { copyToClipboard, smoothScrollToEl } from '@utils'
import React, { useState, useEffect, useContext } from 'react'
import useDocumentScrollThrottled from './useDocumentScrollThrottled'
import {
    Row,
    Menu,
    Inner,
    Wallet,
    Address,
    Wrapper,
    LogoLink,
    Disconnect,
    BurgerWrap,
    MyCollectionLink
} from './styled'

const Nav = () => {
    const [t] = useTranslation()
    const { hash } = useLocation()

    const [open, setOpen] = useState(false)
    const [visible, setVisible] = useState(true)
    const isWide = useMedia('(min-width: 500px)')

    const { hahdleMintButton, onDisconnect, selectedAccount } = useContext(
        Web3Context
    )

    const toggle = () => {
        if (open) {
            document.body.classList.remove('block-scroll')
        } else {
            document.body.classList.add('block-scroll')
        }

        setOpen(!open)
    }

    const close = () => {
        document.body.classList.remove('block-scroll')
        setOpen(false)
    }

    useDocumentScrollThrottled(
        ({
            previousScrollTop,
            currentScrollTop
        }: {
            previousScrollTop: number
            currentScrollTop: number
        }) => {
            const isScrolledUp = previousScrollTop > currentScrollTop

            setTimeout(() => {
                setVisible(currentScrollTop < 50 ? true : isScrolledUp)
            }, 0)
        }
    )

    useEffect(() => {
        if (isString(hash)) {
            setTimeout(() => smoothScrollToEl(last(hash.split('#'))), 0)
        }
    }, [hash])

    return (
        <>
            <Wrapper
                visible={visible}
                selectedAccount={Boolean(selectedAccount)}
            >
                <Container>
                    <Wallet>
                        <Address
                            onClick={() => copyToClipboard(selectedAccount)}
                        >
                            <span>{t('address')}:</span>{' '}
                            <span>
                                {!isWide
                                    ? `${selectedAccount.substring(
                                          0,
                                          6
                                      )} ... ${selectedAccount.substring(
                                          selectedAccount.length - 6,
                                          selectedAccount.length
                                      )}`
                                    : selectedAccount}
                            </span>
                        </Address>
                        <Disconnect onClick={onDisconnect}>
                            {t('disconnect')}
                        </Disconnect>
                    </Wallet>
                    <Inner>
                        <Menu>
                            <Link to={`${Routes.HOME}#${Routes.ABOUT_HASH}`}>
                                {t('nav-about')}
                            </Link>
                            <Link
                                to={`${Routes.HOME}#${Routes.COLLECTION_HASH}`}
                            >
                                {t('nav-collection')}
                            </Link>
                            <Link to={`${Routes.HOME}#${Routes.APP_HASH}`}>
                                {t('nav-app')}
                            </Link>
                            <Link to={`${Routes.HOME}#${Routes.ROADMAP_HASH}`}>
                                {t('nav-roadmap')}
                            </Link>
                        </Menu>

                        <BurgerWrap onClick={toggle}>
                            {open ? <Close /> : <Burger />}
                        </BurgerWrap>

                        <LogoLink to={Routes.HOME} onClick={close}>
                            <img
                                src={LogoImg}
                                width="111"
                                height="59"
                                loading="lazy"
                                alt="logo"
                            />
                        </LogoLink>

                        <Row>
                            <Social
                                color="white"
                                style={
                                    Boolean(selectedAccount)
                                        ? {}
                                        : { marginRight: 50 }
                                }
                            />
                            {Boolean(selectedAccount) && (
                                <MyCollectionLink to={Routes.COLLECTION}>
                                    {t('my-collection')}
                                </MyCollectionLink>
                            )}
                            <Button
                                color="white"
                                style={mintBtnStyle}
                                onClick={hahdleMintButton}
                            >
                                <span>{t('get-llama')}</span>
                            </Button>
                        </Row>
                    </Inner>
                </Container>
            </Wrapper>

            <MobileMenu open={open} close={close} />
        </>
    )
}

export default Nav

const mintBtnStyle = {
    padding: 0,
    height: 52,
    width: 160
}
