export const getScrollTop = () => {
    if (typeof pageYOffset != 'undefined') {
        //most browsers except IE before #9
        return pageYOffset
    } else {
        const B = document.body //IE 'quirks'
        let D = document.documentElement //IE with doctype
        D = D.clientHeight ? D : B
        return D.scrollTop
    }
}

export const smoothScrollToEl = (id?: string) => {
    if (!id) return
    const el = document.getElementById(id)
    if (!el) return
    el.scrollIntoView({ behavior: 'smooth' })
}

export const copyToClipboard = (input: string) => {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(input).then(
            () => {
                console.log('Copied to clipboard successfully.')
            },
            (err) => {
                console.log('Failed to copy the text to clipboard.', err)
            }
        )
    }
}
