import { Routes } from '@variables'
import loadable from '@loadable/component'

const Home = loadable(() => import('./pages/home'))
const Mint = loadable(() => import('./pages/mint'))
const Collection = loadable(() => import('./pages/collection'))

export const routes = () => {
    return [
        {
            component: Mint,
            isPrivate: false,
            path: Routes.MINT
        },
        {
            component: Collection,
            isPrivate: false,
            path: Routes.COLLECTION
        },
        {
            component: Home,
            isPrivate: false,
            path: '*'
        }
    ]
}
