import React from 'react'

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="15"
            fill="none"
            viewBox="0 0 25 15"
        >
            <rect width="25" height="4" fill="#fff" rx="2"></rect>
            <rect width="21" height="4" y="11" fill="#fff" rx="2"></rect>
        </svg>
    )
}

export default Icon
