import { colors } from '@variables'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Web3Context } from 'client/context/Web3'
import Video from 'client/components/shared/Video'
import React, { useContext, useEffect } from 'react'
import { getScrollTop } from '@utils'

export const Wrap = styled.div<{ isLoading: boolean }>`
    z-index: 4;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: ${colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${colors.white};
    visibility: visible;
    padding-bottom: 35px;

    ${({ isLoading }) =>
        !isLoading &&
        css`
            position: -999999;
            visibility: hidden;
        `}
`

const Loader = () => {
    const [t] = useTranslation()
    const { loading } = useContext(Web3Context)

    useEffect(() => {
        if (!document) return

        const isBlocked = document.body.classList.contains('block-scroll')

        if (!isBlocked && loading) {
            document.body.classList.add('block-scroll')
        }

        if (isBlocked && !loading) {
            document.body.classList.remove('block-scroll')
        }

        return () => {
            document.body.classList.remove('block-scroll')
        }
    }, [loading])

    return (
        <Wrap isLoading={loading}>
            <h2>{t('processing')}</h2>
            <Video />
        </Wrap>
    )
}

export default Loader
