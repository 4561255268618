import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { AccessibleButton } from './../shared/styled'
import { colors, fontFamily, media, transition } from '@variables'

export const Wallet = styled.div`
    width: 100%;
    display: flex;
    height: 38px;
    justify-content: flex-end;
`

export const Inner = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
`

export const Wrapper = styled.nav<{
    visible: boolean
    selectedAccount: boolean
}>`
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    position: fixed;
    overflow: visible;
    will-change: transform;
    transition: transform ease-out 0.2s;
    background-color: ${colors.black};

    ${({ selectedAccount, visible }) =>
        selectedAccount
            ? css`
                  height: 142px;
                  transform: ${visible
                      ? 'translateY(0px)'
                      : 'translateY(-142px)'};

                  ${Inner} {
                      height: calc(100% - 38px);
                  }
              `
            : css`
                  height: 104px;
                  transform: ${visible
                      ? 'translateY(0px)'
                      : 'translateY(-104px)'};

                  ${Wallet} {
                      display: none;
                  }
              `}
`

export const Address = styled.p`
    font-size: 12px;
    line-height: 38px;
    margin-right: 12px;
    cursor: pointer;
    transition: ${transition.main};
    color: hsla(360, 100%, 100%, 0.8);

    ${media.tablet} {
        :hover {
            color: hsla(360, 100%, 100%, 1);
        }
    }

    span:first-of-type {
        font-family: ${fontFamily.bold};
    }
`

export const Disconnect = styled(AccessibleButton)`
    font-size: 12px;
    line-height: 38px;
    transition: ${transition.main};
    font-family: ${fontFamily.bold};
    color: hsla(360, 100%, 100%, 0.8);

    ${media.tablet} {
        :hover {
            color: hsla(360, 100%, 100%, 1);
        }
    }
`

export const BurgerWrap = styled(AccessibleButton)`
    display: block;

    ${media.xl} {
        display: none;
    }
`

export const Row = styled.div`
    display: none;
    align-items: center;

    ${media.xl} {
        display: flex;
    }
`

export const Menu = styled(Row)`
    a {
        cursor: pointer;
        color: ${colors.white};
        text-decoration: none;
        font-family: ${fontFamily.bold};

        font-size: 16px;

        &:not(:last-of-type) {
            padding-right: 22px;
        }

        ${media.xl} {
            font-size: 18px;
            &:not(:last-of-type) {
                padding-right: 26px;
            }
        }
    }
`

export const LogoLink = styled(Link)`
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 111px;
    height: 59px;
`

export const MyCollectionLink = styled(Link)`
    padding: 0 24px;
    text-decoration: none;
    color: ${colors.yellow};
    font-family: ${fontFamily.bold};
`
