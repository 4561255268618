/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { hydrate } from 'react-dom'
import { CookiesProvider } from 'react-cookie'
import smoothscroll from 'smoothscroll-polyfill'
import { BrowserRouter } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider, withSSR } from 'react-i18next'
import Web3Provider from 'client/context/Web3'

import App from './App'
import i18n from '../common/i18n'
import ScrollToTop from './components/ScrollToTop'

/**
 * Kick off the polyfills if it needed
 */
smoothscroll.polyfill()

const BaseApp: React.FC = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <CookiesProvider>
                <BrowserRouter>
                    <HelmetProvider>
                        <Web3Provider>
                            <ScrollToTop />
                            <App />
                        </Web3Provider>
                    </HelmetProvider>
                </BrowserRouter>
            </CookiesProvider>
        </I18nextProvider>
    )
}

const ExtendedApp = withSSR()(BaseApp)

/**
 * Wait for all loadable components to be loaded before rendering.
 * Beacuse Loadable components loads all our scripts asynchronously.
 */
loadableReady(() => {
    const root = document.getElementById('root')
    hydrate(
        <ExtendedApp
            initialLanguage={window.initialLanguage}
            initialI18nStore={window.initialI18nStore}
        />,
        root
    )
})

if (module.hot) {
    module.hot.accept()
}
