import { Props } from '.'
import styled, { css } from 'styled-components'
import { colors, fontFamily, media } from '@variables'

export const ButtonWrap = styled.div`
    padding: 0;
    width: 100%;
    display: flex;
    font-size: 26px;
    align-items: center;
    justify-content: center;

    padding: 30px 0 20px 0;

    ${media.custom(420)} {
        padding: 50px 0 34px 0;
    }
    ${media.tablet} {
        padding: 50px 0;
    }

    button {
        min-width: 250px;
    }
`

export const SocialWrap = styled.div`
    a:not(:last-of-type) {
        margin-right: 28px;
    }
    svg {
        width: 34px;
        height: 34px;
        min-width: 34px;
        min-height: 34px;
    }
`

export const Wrap = styled.div<Pick<Props, 'open'>>`
    z-index: -1;
    height: 0;

    ${({ open }) =>
        open &&
        css`
            z-index: 3;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            display: flex;
            position: fixed;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: ${colors.black};

            ${media.custom(540)} {
                overflow-y: scroll;
            }
        `}
`

export const Menu = styled.div`
    padding-top: 90px;

    ${media.custom(420)} {
        padding-top: 0;
    }

    a {
        display: block;
        cursor: pointer;
        text-align: center;
        font-size: 28px;
        text-decoration: none;
        color: ${colors.white};
        letter-spacing: -0.05em;
        font-family: ${fontFamily.bold};

        ${media.custom(420)} {
            font-size: 36px;
        }

        :not(:last-of-type) {
            margin-bottom: 24px;

            ${media.custom(420)} {
                margin-bottom: 36px;
            }
        }
    }
`
