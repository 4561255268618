import { TFunctionResult } from 'i18next'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import { Wrap } from './styled'

export type Props = {
    onClick?: () => void
    style?: CSSProperties
    outlined?: boolean
    color: 'white' | 'black' | 'yellow'
    children: string | React.ReactChild | TFunctionResult
}

const Button = ({ children, color, onClick, outlined, style = {} }: Props) => {
    const [t] = useTranslation()

    return (
        <Wrap color={color} style={style} outlined={outlined} onClick={onClick}>
            {children}
        </Wrap>
    )
}

export default Button
