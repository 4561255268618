import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Social from '../shared/Social'
import LogoImg from '@static/images/logo-footer.webp'

export const Wrap = styled.div`
    width: 100%;
    padding: 42px 0;
    display: flex;
    background: #f0f0f0;
    align-items: center;
    flex-direction: column;
    text-align: center;
`

const Footer = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <img
                src={LogoImg}
                alt="Bullish.club"
                width="111"
                height="59"
                loading="lazy"
            />
            <Social color="black" style={{ margin: '40px 0 16px 0' }} />
            <p>{t('footer-text')}</p>
        </Wrap>
    )
}

export default Footer
