import React from 'react'

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            fill="none"
            viewBox="0 0 27 27"
        >
            <rect
                width="32.756"
                height="4"
                x="3.075"
                y="0.747"
                fill="#fff"
                rx="2"
                transform="rotate(45 3.075 .747)"
            ></rect>
            <rect
                width="32.756"
                height="4"
                fill="#fff"
                rx="2"
                transform="scale(-1 1) rotate(45 -12.606 -27.883)"
            ></rect>
        </svg>
    )
}

export default Icon
