import Web3 from 'web3'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Contract } from 'web3-eth-contract'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import Web3Modal, { IProviderOptions } from 'web3modal'
import { useHistory, useLocation } from 'react-router-dom'
import WalletConnectProvider from '@walletconnect/web3-provider'

import { Routes, contractABI, now, dateOfStartPublic } from '@variables'

type Props = {
    children: React.ReactNode
}

export type Context = {
    tokenPrice?: string
    totalSupply?: number
    loading: boolean
    whitelist: string[]
    userTokens: string[]
    maxTokensPerTransaction: number
    selectedAccount: string
    mint: TODO_ANY
    onConnect: TODO_ANY
    onDisconnect: TODO_ANY
    hahdleMintButton: TODO_ANY
}

/**
 * Web3Context
 */
export const Web3Context = React.createContext<Context>({
    tokenPrice: '0',
    totalSupply: 0,
    maxTokensPerTransaction: 20,
    selectedAccount: '',
    loading: false,
    whitelist: [],
    userTokens: [],
    onConnect: async () => {},
    onDisconnect: async () => {},
    hahdleMintButton: async () => {},
    mint: async (amount: number) => {}
})

let web3: Web3
let contract: Contract
let provider: TODO_ANY
let web3Modal: Web3Modal

const Web3Provider = (props: Props) => {
    const history = useHistory()
    const [t] = useTranslation()
    const { pathname } = useLocation()

    const [loading, setLoading] = useState(false)
    const [whitelist, setWhitelist] = useState<string[]>([])
    const [selectedAccount, setSelectedAccount] = useState('')
    const [userTokens, setUserTokens] = useState<string[]>([])
    const [tokenPrice, setTokenPrice] = useState<string | undefined>(undefined)
    const [maxTokensPerTransaction, setMaxTokensPerTransaction] = useState(20)
    const [totalSupply, setTotalSupply] = useState<number | undefined>(
        undefined
    )

    const init = async () => {
        // web3modal setup initialization, no need to change anything
        const providerOptions: IProviderOptions = {
            walletconnect: {
                package: WalletConnectProvider,
                options: {
                    rpc: {
                        1: 'https://main-light.eth.linkpool.io'
                    }
                }
            }
        }

        web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions,
            disableInjectedProvider: false
        })

        // if already cached login, connect automatically
        if (web3Modal.cachedProvider !== '') {
            onConnect()
        }
    }

    const onDisconnect = async () => {
        if (provider.close) {
            await provider.close()
            web3Modal.clearCachedProvider()
            provider = null
        }

        web3Modal.clearCachedProvider()
        setSelectedAccount('')
        history.push(Routes.HOME)
        toast(t('wallet-disconnected'), {
            icon: t('emoji-success'),
            position: 'bottom-left'
        })
    }

    const onConnect = async () => {
        try {
            provider = await web3Modal.connect()
        } catch (e) {
            console.log('Could not get a wallet connection', e)
            return
        }

        // Subscribe to accounts change
        provider.on('accountsChanged', (accounts: TODO_ANY) => {
            fetchAccountData()
        })

        // Subscribe to chainId change
        provider.on('chainChanged', (chainId: TODO_ANY) => {
            fetchAccountData()
        })

        // Subscribe to networkId change
        provider.on('networkChanged', (networkId: TODO_ANY) => {
            fetchAccountData()
        })

        await fetchAccountData()

        toast(t('connected'), {
            icon: t('emoji-success'),
            position: 'bottom-left'
        })
    }

    const fetchAccountData = async (newProvider?: TODO_ANY) => {
        web3 = new Web3(newProvider || provider)

        const chainId: number = await web3.eth.getChainId()

        if (`${chainId}` !== `${process.env.RAZZLE_CONTRACT_CHAIN_ID}`) {
            toast(t('wrong-selected-network'), {
                icon: t('emoji-error'),
                position: 'bottom-left'
            })
            return
        }

        // get account public address
        const accounts = await web3.eth.getAccounts()
        setSelectedAccount(accounts[0])

        await loadContracts()
    }

    const loadContracts = async () => {
        contract = new web3.eth.Contract(
            contractABI,
            process.env.RAZZLE_CONTRACT_ADDRESS
        )

        const totalSupply = await contract.methods.totalSupply().call()
        setTotalSupply(parseInt(totalSupply, 10))

        const price = await contract.methods.getTokenPrice().call()
        setTokenPrice(price)

        history.push(Routes.HOME)
    }

    const loadAndFillCollection = async () => {
        if (!contract?.methods) return
        if (pathname !== Routes.COLLECTION) return

        setLoading(true)

        const ownedAmount = await contract.methods
            .balanceOf(selectedAccount)
            .call()

        const newUserTokens = []
        for (let i = 0; i < ownedAmount; i++) {
            const tokenId = await contract.methods
                .tokenOfOwnerByIndex(selectedAccount, i)
                .call()
            newUserTokens.push(tokenId)
        }

        setUserTokens(newUserTokens)
        setLoading(false)
    }

    const mint = async (amount: number) => {
        if (amount <= 0) {
            toast(t('amount-not-null'), {
                icon: t('emoji-info'),
                position: 'bottom-left'
            })
            return
        }

        if (amount > maxTokensPerTransaction) {
            toast(
                `${t(
                    'max-tokens-per-transaction'
                )} = ${maxTokensPerTransaction}`,
                {
                    icon: t('emoji-info'),
                    position: 'bottom-left'
                }
            )
            return
        }

        if (!selectedAccount) {
            toast(t('connect-wallet'), {
                icon: t('emoji-info'),
                position: 'bottom-left'
            })
            return
        }

        await mintPublicMultipleTokens(amount)
    }

    const mintPublicMultipleTokens = async (amount: number) => {
        const price = await contract.methods.getTokenPrice().call()
        setLoading(true)

        contract.methods
            .mintPublicMultipleTokens(amount)
            .send({
                to: contract,
                from: selectedAccount,
                value: price * amount,
                gas: 200000 * amount
            })
            .on('error', (error: TODO_ANY) => {
                console.log('on error')
                console.error(error)
                toast(t('error-check-console'), {
                    icon: t('emoji-error'),
                    position: 'bottom-left'
                })
            })
            .on('transactionHash', (transactionHash: TODO_ANY) => {
                console.log('on transactionHash')
                console.log(transactionHash)
                toast(`${t('transaction-hash')}: ${transactionHash}`, {
                    icon: t('emoji-success'),
                    position: 'bottom-left',
                    duration: 10000,
                    style: { wordBreak: 'break-word' }
                })
            })
            .on('receipt', (receipt: TODO_ANY) => {
                console.log('on receipt')
                console.log(receipt)
                toast(t('confirmation-success'), {
                    icon: t('emoji-success'),
                    position: 'bottom-left'
                })
            })
            // .on(
            //     'confirmation',
            //     (confirmationNumber: TODO_ANY, receipt: TODO_ANY) => {
            //         console.log('on confirmation')
            //         console.log(confirmationNumber, receipt)
            //     }
            // )
            .then((res: TODO_ANY) => {
                console.log('on success')
                console.log(res)
                setLoading(false)
                toast(t('success'), {
                    icon: t('emoji-success'),
                    position: 'bottom-left'
                })
            })
            .catch((err: TODO_ANY) => {
                console.log('catch error')
                console.error(err)
                setLoading(false)
                toast(t('error-check-console'), {
                    icon: t('emoji-error'),
                    position: 'bottom-left'
                })
            })
    }

    const hahdleMintButton = async () => {
        if (selectedAccount) {
            history.push(Routes.MINT)
        } else {
            await onConnect()
        }
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        const fetch = async () => {
            await loadAndFillCollection()
        }

        fetch()
    }, [contract, pathname])

    useEffect(() => {
        if (now >= dateOfStartPublic) {
            return
        }

        const fetch = async () => {
            try {
                const { data } = await axios.get('/whitelist')
                setWhitelist(data)
            } catch (err) {
                console.error(err)
            }
        }
        fetch()
    }, [])

    return (
        <Web3Context.Provider
            value={{
                loading,
                tokenPrice,
                totalSupply,
                selectedAccount,
                userTokens,
                whitelist,
                mint,
                onConnect,
                onDisconnect,
                hahdleMintButton,
                maxTokensPerTransaction
            }}
        >
            {props.children}
        </Web3Context.Provider>
    )
}

export default Web3Provider
