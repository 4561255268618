import React from 'react'
import { Switch } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

import './App.css'
import { routes } from './routes'
import AppRoute from './AppRoute'
import Nav from './components/Nav'
import Loader from './components/Loader'
import Footer from './components/Footer'
import { PageWrapper } from './components/shared/styled'

const App = () => {
    return (
        <>
            <Toaster />
            <Loader />
            <Nav />
            <PageWrapper>
                <Switch>
                    {routes().map((route) => (
                        <AppRoute
                            exact
                            key={route.path}
                            path={route.path}
                            isPrivate={route.isPrivate}
                            component={route.component}
                        />
                    ))}
                </Switch>
            </PageWrapper>

            <Footer />
        </>
    )
}

export default App
