import React, { ReactNode, CSSProperties } from 'react'

import { Wrapper } from './styled'

type Props = {
    children: ReactNode | ReactNode[]
    maxWidth?: number
    className?: string
    style?: CSSProperties
}

const Container = ({
    children,
    maxWidth,
    style = {},
    className = ''
}: Props) => (
    <Wrapper maxWidth={maxWidth} style={style} className={className}>
        {Array.isArray(children) ? children.map((c) => c) : children}
    </Wrapper>
)

export default Container
